import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Drawer, Divider, ListSubheader, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { People as PeopleIcon, Search as SearchIcon, MoneyOff as MoneyOffIcon, Add as AddIcon } from "@material-ui/icons";

import Reports from "../routes/reports";
import Trials from "../routes/trials";
import Search from "../routes/search";
import Accounts from "../routes/accounts";
import Login from "../routes/login";
import ProtectedRoute from "./ProtectedRoute";
import { useGoogleAuth } from "../contexts/auth";

function Navigation() {
  const { isInitialized } = useGoogleAuth();
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      position: "relative",
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      height: "90%"
    },
  }));

  const classes = useStyles();
  const MAIN_LINKS = [{
    link: "/",
    icon: <SearchIcon />,
    text: "Search"
  }, {
    link: "/accounts/create",
    icon: <AddIcon />,
    text: "Create Account"
  }, {
    link: "/trials",
    icon: <MoneyOffIcon />,
    text: "Trial Requests"
  }];

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.appBarSpacer} />

        {MAIN_LINKS.map((l, i) => (
          <ListItem key={i} button component={Link} to={l.link}>
            <ListItemIcon>
              {l.icon}
            </ListItemIcon>
            <ListItemText primary={l.text} />
          </ListItem>
        ))}

        <Divider />

        <ListSubheader inset>Reports</ListSubheader>
        <ListItem button component={Link} to={"/reports/accountList"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Account List" />
        </ListItem>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {isInitialized && (
              <Switch>
                <Route component={Login} path="/login" />
                <ProtectedRoute component={Trials} path="/trials" />
                <ProtectedRoute component={Reports} path="/reports" />
                <ProtectedRoute component={Accounts} path="/accounts" />
                <ProtectedRoute component={Search} path="/" exact={true} />
              </Switch>
            )}
        </Container>
      </main>
    </>
  );
}

export default Navigation;
