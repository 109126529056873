import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, Grid, Button, Box, CircularProgress, Paper, Typography, Divider, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { DEFAULT_SNACKBAR_TIME, DEFAULT_SNACKBAR, ID_TO_TRIAL_STATUS, getError } from "../../utils/constants";

function TrialView() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto"
    },
    spacer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    rejectBtn: {
      backgroundColor: theme.palette.error.main,
      color: "#FFFFFF",
      marginRight: theme.spacing(2),
    },
    alertList: {
      marginBottom: 0,
      listStyleType: "none"
    },
    alertListItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  }));

  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [trial, setTrial] = useState({});
  const [trialValidation, setTrialValidation] = useState({});
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    return () => {
      setTrial({});
      setTrialValidation({});
      setSnackbar(DEFAULT_SNACKBAR);
      setError(undefined);
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/trials/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        }
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const trialJson = await res.json();
      setTrial(trialJson.trial);
      setTrialValidation(Object.assign(trialJson.validation, {emailDomain: trialJson.email_domain}));
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const addUser = async (event, acctID) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          acctID,
          firstName: trial.firstName,
          lastName: trial.lastName,
          email: trial.email
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const userIDJson = await res.json();
      const userID = userIDJson.userID;
      history.push(`/accounts/${acctID}/users/${userID}`);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const rejectRequest = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/trials/${id}/reject`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.go(0);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const createAcctAndAddUser = async (event) => {
    event.preventDefault();
    let acctID = undefined;

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          acctName: trial.company,
          acctTypeID: 3,
          contact: `${trial.firstName} ${trial.lastName}`,
          phone: trial.phone,
          contactEmail: trial.email
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const acctIDJson = await res.json();
      acctID = acctIDJson.acctID;
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }

    if (!acctID) {
      setSnackbar({type: "error", open: true, message: "Error creating account."});
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify({
          firstName: trial.firstName,
          lastName: trial.lastName,
          email: trial.email,
          userStatusID: 1, // default to "active"
          acctID,
          password: "",
        }),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      history.push(`/accounts/${acctID}`);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const TrialValidation = () => {
    if (trial.trialStatusID !== 1) {
      return;
    }

    return (
      <>
        {trialValidation.emailCheck.length > 0 ? (
          <Alert className={classes.spacer} severity="error">
            The email address below is associated with a user in the account below.

            <ul className={classes.alertList}>
              {trialValidation.emailCheck.map((e, i) => (
                <li className={classes.alertListItem} key={i}>
                  <Link to={`/accounts/${e.acctID}`}>{e.acctID} - {e.acctName}</Link>
                </li>
              ))}
            </ul>
          </Alert>
        ) : (
          trialValidation.emailSuggest.length > 0 && (
            <Alert className={classes.spacer} severity="warning">
            Other "{trialValidation.emailDomain}" email addresses were found in the account(s) below.

            <ul className={classes.alertList}>
              {trialValidation.emailSuggest.map((e, i) => (
                <li className={classes.alertListItem} key={i}>
                  <Link to={`/accounts/${e.acctID}`}>{e.acctID} - {e.acctName}</Link>
                  {trialValidation.emailCheck.length === 0 && <Button size="small" variant="text" onClick={ev => addUser(ev, e.acctID)} className={classes.addUserLink}>Add user</Button>}
                </li>
              ))}
            </ul>
          </Alert>
          )
        )}

        {trialValidation.companyCheck.length > 0 && (
          <Alert className={classes.spacer} severity="warning">
            The company name specified matches the account(s) below.

            <ul className={classes.alertList}>
              {trialValidation.companyCheck.map((e, i) => (
                <li className={classes.alertListItem} key={i}>
                  <Link to={`/accounts/${e.acctID}`}>{e.acctID} - {e.acctName}</Link>
                  {trialValidation.emailCheck.length === 0 && <Button size="small" variant="text" onClick={ev => addUser(ev, e.acctID)} className={classes.addUserLink}>Add user</Button>}
                </li>
              ))}
            </ul>
          </Alert>
        )}
      </>
    );
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
        <Alert severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Title>
            Trial Request
          </Title>

          <Typography variant="caption" display="block" gutterBottom>
            The trial request information below was submitted for approval.
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            {trial.trialStatusID === 1 && (
              <>
                <Button variant="contained" className={classes.rejectBtn} onClick={rejectRequest}>
                  Reject request
                </Button>
                {trialValidation.companyCheck.length === 0 && trialValidation.emailCheck.length === 0 && <Button variant="contained" color="primary" onClick={createAcctAndAddUser}>
                  Create account and add user
                </Button>}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider />

      {TrialValidation()}

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {Object.entries(trial).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  {key}
                </TableCell>
                <TableCell>{key === "trialStatusID" ? ID_TO_TRIAL_STATUS[value] : value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TrialView;
