import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";

import { makeStyles } from "@material-ui/core/styles";
import { FormLabel, Snackbar, Grid, Button, Box, CircularProgress, TableHead, Paper, Tab, Typography, Divider, TextField, FormControl, Select, InputLabel, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { DEFAULT_SNACKBAR_TIME, DEFAULT_SNACKBAR, ID_TO_ACCT_STATUS, ID_TO_ACCT_TYPE, getError } from "../../utils/constants";
import { getCCToken } from "../../utils/cc";

const TAB_TO_API_ENDPOINT = {
  0: "info",
  1: "users",
  2: "subscription",
  3: "address",
  4: "notes",
  5: "reference",
}

function AccountView() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto"
    },
    spacer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    userRow: {
      textDecoration: "none",
    }
  }));

  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [tab, setTab] = useState("0");
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    return () => {
      setAccount({});
      setTab("0");
      setSnackbar(DEFAULT_SNACKBAR);
      setError(undefined);
    }
  }, []);

  const fetchData = async () => {
    for (const value of Object.values(TAB_TO_API_ENDPOINT)) {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${id}/${value}`, {
          headers: {
            "Authorization": `Bearer ${googleUser.tokenId}`,
          }
        });

        if (!res.ok) {
          throw new Error(await res.text());
        }

        const accountJson = await res.json();
        setAccount(Object.assign(account, {[value]: accountJson}));
      } catch (e) {
        console.error(e);
        setError({type: "error", open: true, message: getError(e)});
      }
    }

    setIsLoading(false);
  }

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTab(newValue);
  }

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
          "CC-Authorization": `${getCCToken()}`,
        },
        body: JSON.stringify(account),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleFieldChange = (event) => {
    event.preventDefault();
    const [section, property] = event.target.id.split(".");
    setAccount({...account, [section]: {...account[section], [property]: event.target.value}});
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
        <Alert severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Title>
            {account.info.acctName}
          </Title>

          <Typography variant="caption" display="block" gutterBottom>
            Account ID: {account.info.acctID}
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <TabContext value={tab}>
        <TabList
          value={tab}
          onChange={handleTabChange}
        >
          <Tab label="Basic Info" value="0" />
          <Tab label="Users" value="1" />
          <Tab label="Subscription" value="2" />
          <Tab label="Address" value="3" />
          <Tab label="Notes" value="4" />
          <Tab label="Reference" value="5" />
        </TabList>

        <TabPanel value="0">
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="info.acctName" label="Account Name" onChange={handleFieldChange} value={account.info.acctName} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="account-status-selector">Account Status</InputLabel>
            <Select
              labelId="account-status-selector"
              id="info.acctStatusID"
              value={account.info.acctStatusID}
              onChange={handleFieldChange}
              native={true}
              variant="outlined"
            >
              {Object.keys(ID_TO_ACCT_STATUS).map(i => <option key={i} value={i}>{ID_TO_ACCT_STATUS[i]}</option>)}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="account-type-selector">Account Type</InputLabel>
            <Select
              labelId="account-type-selector"
              id="info.acctTypeID"
              value={account.info.acctTypeID}
              onChange={handleFieldChange}
              native={true}
              variant="outlined"
            >
              {Object.keys(ID_TO_ACCT_TYPE).map(i => <option key={i} value={i}>{ID_TO_ACCT_TYPE[i]}</option>)}
            </Select>
            <Typography variant="caption" display="block">
              Changing the account type may also change the account status depending on the option selected
            </Typography>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="info.contact" label="Contact Name" onChange={handleFieldChange} value={account.info.contact} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="info.phone" label="Contact Phone" onChange={handleFieldChange} value={account.info.phone} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="info.contactEmail" label="Contact Email" onChange={handleFieldChange} value={account.info.contactEmail} />
          </FormControl>
        </TabPanel>

        <TabPanel value="1">
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => history.push(`/accounts/${account.info.acctID}/users/create`)}>
              New User
            </Button>
          </Box>

          <Typography variant="overline" display="block" color="textSecondary">
            Showing {account.users.length.toLocaleString()} users
          </Typography>

          <TableContainer component={Paper}>
            <Table classNames={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {account.users.map((user, i) => (
                  <TableRow hover={true} component={Link} className={classes.userRow} state={{acctID: account.info.acctID}} to={`/accounts/${account.info.acctID}/users/${user.UserID}`} key={i}>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    <TableCell>
                      {user.firstName}
                    </TableCell>
                    <TableCell>
                      {user.lastName}
                    </TableCell>
                    <TableCell>
                      {user.userStatusID === 1 ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value="2">
          <FormControl fullWidth margin="normal">
            <TextField
              id="subscription.trialStarts"
              label="Trial Starts"
              onChange={handleFieldChange}
              defaultValue={() => {
                const d = dayjs(account.subscription.trialStarts);
                if (d.isValid()) {
                  return d.format("M/DD/YYYY");
                } else {
                  return "";
                }
              }}
              value={account.subscription.trialStarts}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              id="subscription.trialEnds"
              label="Trial Ends"
              onChange={handleFieldChange}
              defaultValue={() => {
                const d = dayjs(account.subscription.trialEnds);
                if (d.isValid()) {
                  return d.format("M/DD/YYYY");
                } else {
                  return "";
                }
              }}
              value={account.subscription.trialEnds}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              id="subscription.subscriptionStarts"
              label="Subscription Starts" onChange={handleFieldChange}
              defaultValue={() => {
                const d = dayjs(account.subscription.subscriptionStarts);
                if (d.isValid()) {
                  return d.format("M/DD/YYYY");
                } else {
                  return "";
                }
              }}
              value={account.subscription.subscriptionStarts}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              id="subscription.subscriptionEnds"
              label="Subscription Ends"
              onChange={handleFieldChange}
              defaultValue={() => {
                const d = dayjs(account.subscription.subscriptionEnds);
                if (d.isValid()) {
                  return d.format("M/DD/YYYY");
                } else {
                  return "";
                }
              }}
              value={account.subscription.subscriptionEnds}
              variant="outlined"
            />
          </FormControl>
        </TabPanel>

        <TabPanel value="3">
          <FormLabel component="legend">Mailing Address</FormLabel>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.address" label="Address" onChange={handleFieldChange} value={account.address.address} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.address2" onChange={handleFieldChange} value={account.address.address2} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.city" label="City" onChange={handleFieldChange} value={account.address.city} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.state" label="State" onChange={handleFieldChange} value={account.address.state} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.zip" label="Zip" onChange={handleFieldChange} value={account.address.zip} />
          </FormControl>

          <Divider className={classes.spacer} />

          <FormLabel component="legend">Billing Address</FormLabel>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.billingAddress" label="Address" onChange={handleFieldChange} value={account.address.billingAddress} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.billingAddress2" onChange={handleFieldChange} value={account.address.billingAddress2} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.billingCity" label="City" onChange={handleFieldChange} value={account.address.billingCity} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.billingState" label="State" onChange={handleFieldChange} value={account.address.billingState} />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" id="address.billingZip" label="Zip" onChange={handleFieldChange} value={account.address.billingZip} />
          </FormControl>
        </TabPanel>

        <TabPanel value="4">
          <FormControl fullWidth>
            <TextField
              id="notes.note"
              label="Notes"
              multiline
              rows={15}
              onChange={handleFieldChange}
              value={account.notes.note ? account.notes.note : ""}
              variant="outlined"
            />
          </FormControl>
        </TabPanel>

        <TabPanel value="5">
          <Alert className={classes.spacer} severity="info">
            This is just a dump of the other data fields copied over from stateabase on 1/16/2018.
          </Alert>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Object.entries(account.reference).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      {key}
                    </TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>
    </Paper>
  );
}

export default AccountView;
