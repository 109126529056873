import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import AccountList from "./AccountList";
import ProtectedRoute from "../../components/ProtectedRoute";

function Reports() {
  const match = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${match.path}/accountList`}>
        <AccountList />
      </ProtectedRoute>
    </Switch>
  );
}

export default Reports;
