import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import AccountView from "./AccountView";
import CreateAccountView from "./CreateAccountView";
import UserView from "./UserView";
import CreateUserView from "./CreateUserView";
import ProtectedRoute from "../../components/ProtectedRoute";

function Accounts() {
  const match = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${match.path}/:acctID/users/create`}>
        <CreateUserView />
      </ProtectedRoute>
      <ProtectedRoute path={`${match.path}/:acctID/users/:id`}>
        <UserView />
      </ProtectedRoute>
      <ProtectedRoute path={`${match.path}/create`}>
        <CreateAccountView />
      </ProtectedRoute>
      <ProtectedRoute path={`${match.path}/:id`}>
        <AccountView />
      </ProtectedRoute>
    </Switch>
  );
}

export default Accounts;
