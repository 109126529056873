import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Column, Table, AutoSizer } from "react-virtualized";
import dayjs from "dayjs";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper, FormControl, Select, InputLabel, MenuItem, Divider, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { getError, ID_TO_ACCT_STATUS, ID_TO_ACCT_TYPE } from "../../utils/constants";

function AccountList() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "hidden"
    },
    formControl: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 150,
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [accountStatusFilter, setAccountStatusFilter] = useState(-1);
  const [accountTypeFilter, setAccountTypeFilter] = useState(-1);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();

    return () => {
      setAccountStatusFilter(-1);
      setAccountTypeFilter(-1);
      setFilteredAccounts([]);
      setAccounts([]);
      setError(undefined);
    }
  }, []);

  useEffect(() => {
    setFilteredAccounts(accounts);
  }, [accounts]);

  useEffect(() => {
    filterAccounts();
  }, [accountStatusFilter, accountTypeFilter]);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const accountsJson = await res.json();
      setAccounts(accountsJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const filterAccounts = () => {
    const filtered = accounts.filter(a => {
      let condition = true;
      if (accountStatusFilter !== -1) {
        condition = a.acctStatusID == accountStatusFilter;
      }

      if (accountTypeFilter !== -1) {
        condition = condition && a.acctTypeID == accountTypeFilter;
      }

      return condition;
    });

    setFilteredAccounts(filtered);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Title>Account List</Title>

      <FormControl className={classes.formControl}>
        <InputLabel id="account-status-selector">Account Status</InputLabel>
        <Select
          labelId="account-status-selector"
          id="account-status-select"
          value={accountStatusFilter}
          defaultValue={-1}
          onChange={e => setAccountStatusFilter(e.target.value)}
          autoWidth={true}
        >
          <MenuItem value={-1}>All</MenuItem>
          {Object.keys(ID_TO_ACCT_STATUS).map(i => <MenuItem key={i} value={i}>{ID_TO_ACCT_STATUS[i]}</MenuItem>)}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="account-type-selector">Account Type</InputLabel>
        <Select
          labelId="account-type-selector"
          id="account-type-select"
          value={accountTypeFilter}
          defaultValue={-1}
          onChange={e => setAccountTypeFilter(e.target.value)}
          autoWidth={true}
        >
          <MenuItem value={-1}>All</MenuItem>
          {Object.keys(ID_TO_ACCT_TYPE).map(i => <MenuItem key={i} value={i}>{ID_TO_ACCT_TYPE[i]}</MenuItem>)}
        </Select>
      </FormControl>

      <Divider />

      <Typography variant="overline" display="block" color="textSecondary">
        Showing {filteredAccounts.length.toLocaleString()} accounts {(accountTypeFilter !== -1 || accountStatusFilter !== -1) && "(filtered)"}
      </Typography>

      <AutoSizer>
        {({height, width}) => (
          <Table
            height={height}
            width={width}
            rowHeight={40}
            rowGetter={({ index }) => {
              const row = filteredAccounts[index];
              row["tsUpdatedFormatted"] = dayjs(row["tsUpdated"]).format("MM-DD-YYYY");
              return row;
            }}
            rowCount={filteredAccounts.length}
            headerHeight={40}
            onRowClick={({ rowData }) => history.push(`/accounts/${rowData.acctID}`)}
          >
            <Column
              label="Account ID"
              dataKey="acctID"
              width={200}
            />
            <Column
              label="Account Name"
              dataKey="acctName"
              width={500}
            />
            <Column
              label="Old Account ID"
              dataKey="oldCompanyID"
              width={200}
            />
            <Column
              label="Modified"
              dataKey="tsUpdatedFormatted"
              width={200}
            />
          </Table>
        )}
      </AutoSizer>
    </Paper>
  );
}

export default AccountList;
