export const CC_LOCAL_STORAGE_KEY = "CC_TOKEN";
export const ConstantContactAuthorizationURL = `https://api.cc.email/v3/idfed?client_id=${process.env.REACT_APP_CC_API_KEY}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_URL + "/login")}&response_type=token&scope=account_read+account_update+contact_data+campaign_data`;

export const ccLoginValid = () => {
  return true;
  // return localStorage.getItem(CC_LOCAL_STORAGE_KEY) !== null
  //   && Date.now() < parseInt(JSON.parse(localStorage.getItem(CC_LOCAL_STORAGE_KEY)).expiresAt);
}

export const getCCToken = () => {
  const lsCC = localStorage.getItem(CC_LOCAL_STORAGE_KEY);

  if (lsCC !== null) {
    const ccJson = JSON.parse(lsCC);
    return ccJson.token;
  }
}
