import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, FormControl, Button, TextField, InputLabel, Select, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { DEFAULT_NEW_ACCOUNT, DEFAULT_SNACKBAR, ID_TO_ACCT_TYPE, DEFAULT_SNACKBAR_TIME, getError } from "../../utils/constants";

function AccountView() {
  const { googleUser } = useGoogleAuth();
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto"
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const [account, setAccount] = useState(DEFAULT_NEW_ACCOUNT);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);

  useEffect(() => {
    return () => {
      setAccount(DEFAULT_NEW_ACCOUNT);
      setSnackbar(DEFAULT_SNACKBAR);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
        body: JSON.stringify(account),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const acctIDJson = await res.json();
      const acctID = acctIDJson.acctID;
      history.push(`/accounts/${acctID}`);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }

  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  const handleFieldChange = (event) => {
    event.preventDefault();
    const property = event.target.id;
    setAccount({...account, [property]: event.target.value});
  }

  return (
    <Paper className={classes.paper}>
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
        <Alert severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Title>
        New Account
      </Title>

      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="acctName" label="Account Name" onChange={handleFieldChange} value={account.acctName} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="account-type-selector">Account Type</InputLabel>
        <Select
          labelId="account-type-selector"
          id="acctTypeID"
          value={account.acctTypeID}
          onChange={handleFieldChange}
          native={true}
          variant="outlined"
        >
          {Object.keys(ID_TO_ACCT_TYPE).map(i => <option key={i} value={i}>{ID_TO_ACCT_TYPE[i]}</option>)}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Create
      </Button>
    </Paper>
  );
}

export default AccountView;
