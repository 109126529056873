import React, { createContext, useContext } from "react";

import { useGoogleLogin } from "react-use-googlelogin";

const GoogleAuthContext = createContext();

export const GoogleAuthProvider = ({ children }) => {
  const googleAuth = useGoogleLogin({
    clientId: "226829674973-nopdfkgqc871n8vp4osb2fknddegk2gm.apps.googleusercontent.com",
    persist: true,
  });

  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {children}
    </GoogleAuthContext.Provider>
  )
}

export const useGoogleAuth = () => useContext(GoogleAuthContext);
