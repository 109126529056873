import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Paper, CircularProgress, Grid, List, ListItemText, ListItem } from "@material-ui/core";
import { Autocomplete, Alert, createFilterOptions } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { getError } from "../../utils/constants";

function Search() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [recentlyUpdatedAccounts, setRecentlyUpdatedAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchRecentlyUpdatedAccounts();
    fetchAccounts();
    fetchUsers();
    setIsLoading(false);

    return () => {
      setRecentlyUpdatedAccounts([]);
      setAccounts([]);
      setUsers([]);
      setError(undefined);
    }
  }, []);

  const fetchRecentlyUpdatedAccounts = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/recentlyUpdated`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const accountsJson = await res.json();
      setRecentlyUpdatedAccounts(accountsJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }
  }

  const fetchAccounts = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const accountsJson = await res.json();
      setAccounts(accountsJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }
  }

  const fetchUsers = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const usersJson = await res.json();
      setUsers(usersJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Paper className={classes.paper}>
          <Title>
            Search
          </Title>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                label="Go to account ID"
                variant="outlined"
                style={{ width: 300 }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    history.push(`/accounts/${e.target.value}`);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                filterOptions={createFilterOptions({
                  stringify: option => option.acctName,
                })}
                id="search-account"
                options={accounts}
                getOptionLabel={option => option.acctName}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Search by account name" variant="outlined" />}
                onChange={(_, value) => {
                  history.push(`/accounts/${value.acctID}`);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                filterOptions={createFilterOptions({
                  stringify: option => `${option.email}${option.firstName}${option.lastName}`,
                })}
                id="search-user"
                options={users}
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Search by user name or email" variant="outlined" />}
                onChange={(_, value) => {
                  history.push(`/accounts/${value.acctID}/users/${value.userID}`);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <Paper className={classes.paper}>
          <Title>
            Recently Added or Updated Accounts
          </Title>
          <List dense>
            {recentlyUpdatedAccounts.map((acct, i) => (
              <ListItem key={i} component={Link} to={`/accounts/${acct.acctID}`}>
                <ListItemText primary={acct.acctName} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Search;
