import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";

import TrialList from "./TrialList";
import TrialView from "./TrialView";

function Reports() {
  const match = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${match.path}/:id`}>
        <TrialView />
      </ProtectedRoute>
      <ProtectedRoute path={`${match.path}`}>
        <TrialList />
      </ProtectedRoute>
    </Switch>
  );
}

export default Reports;
