export const ID_TO_ACCT_STATUS = {
  0: "Disabled",
  1: "Active",
  2: "Locked",
}

export const ID_TO_USER_STATUS = {
  0: "Disabled",
  1: "Active",
  2: "Locked",
}

export const ID_TO_ACCT_TYPE = {
  1: "Cancellation",
  2: "Current Trial",
  3: "Delinquent",
  4: "Lead",
  5: "Pending Trial",
  6: "Previous Trial",
  7: "Roundup",
  8: "Subscriber",
  9: "Trial Blocked",
  10: "Subscription Expired",
  11: "House",
  12: "Test",
}

export const ID_TO_TRIAL_STATUS = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
}

export const DEFAULT_SNACKBAR = {
  type: "",
  open: false,
  message: "",
}

export const DEFAULT_NEW_ACCOUNT = {
  acctName: "",
  acctTypeID: 8, // default to "subscriber"
}

export const DEFAULT_SNACKBAR_TIME = 6000;

export const DEFAULT_NEW_USER = {
  firstName: "",
  lastName: "",
  email: "",
  userStatusID: 1, // default to "active"
  acctID: -1,
  password: "",
}

export const getError = (e) => {
  try {
    return JSON.parse(e.message).message;
  } catch (e) {
    return "Something went wrong. Try again.";
  }
}
