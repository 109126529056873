import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Checkbox, Snackbar, Grid, Button, Box, CircularProgress, Paper, Typography, Divider, TextField, FormControl, Select, InputLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { DEFAULT_SNACKBAR_TIME, DEFAULT_SNACKBAR, ID_TO_USER_STATUS, getError } from "../../utils/constants";
import { getCCToken } from "../../utils/cc";

function UserView() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto"
    },
    acctLink: {
      color: theme.palette.primary.main
    }
  }));

  const classes = useStyles();

  const { acctID, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [user, setUser] = useState({});
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, [id, acctID]);

  useEffect(() => {
    return () => {
      setAccount({});
      setUser({});
      setSnackbar(DEFAULT_SNACKBAR);
      setError(undefined);
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${acctID}/info`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const accountJson = await res.json();
      setAccount(accountJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    }

    try {
      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${id}`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      const userJson = await res1.json();
      setUser(userJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
          "CC-Authorization": `${getCCToken()}`,
        },
        body: JSON.stringify(user),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      if (resJson.hasOwnProperty("error_code") && resJson.error_code === "MISSING_TRANSACTION_ID") {
        setSnackbar({type: "error", open: true, message: resJson.error_msg});
        return;
      }

      setSnackbar({type: "success", open: true, message: "Success!"});
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleFieldChange = (event) => {
    event.preventDefault();
    const property = event.target.id;
    setUser({...user, [property]: event.target.value});
  }

  const handleToggleChange = (event, id) => {
    event.preventDefault();
    setUser({...user, [id]: event.target.checked});
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
        <Alert severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Title>
            {`${user.firstName || ""} ${user.lastName || ""}`}
          </Title>
          <Typography variant="caption" display="block" gutterBottom>
            User ID: {user.userID}
          </Typography>

          <Typography variant="caption" display="block" gutterBottom>
            <Link className={classes.acctLink} to={`/accounts/${acctID}`}>Account Name: {account.acctName} | Account ID: {account.acctID}</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="firstName" label="First Name" onChange={handleFieldChange} value={user.firstName} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="lastName" label="Last Name" onChange={handleFieldChange} value={user.lastName} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="user-status-selector">User Status</InputLabel>
        <Select
          labelId="user-status-selector"
          id="userStatusID"
          value={user.userStatusID}
          onChange={handleFieldChange}
          native={true}
          variant="outlined"
        >
          {Object.keys(ID_TO_USER_STATUS).map(i => <option key={i} value={i}>{ID_TO_USER_STATUS[i]}</option>)}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="acctID" label="Account ID" onChange={handleFieldChange} value={user.acctID} />
        <Typography variant="caption" display="block">
          Edit to move user to another account
        </Typography>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="email" label="Email" onChange={handleFieldChange} value={user.email} />
        <Typography variant="caption" display="block">
          Must be unique across all accounts
        </Typography>
      </FormControl>
      <FormControl margin="normal">
        <FormControlLabel
          id="isValidated"
          onChange={e => handleToggleChange(e, "isValidated")}
          value={user.isValidated}
          checked={user.isValidated}
          control={<Checkbox />}
          label="User confirmed email"
          labelPlacement="end"
        />
      </FormControl>
      <FormControl margin="normal">
        <FormControlLabel
          id="postEmailOptOut"
          onChange={e => handleToggleChange(e, "postEmailOptOut")}
          value={user.postEmailOptOut}
          checked={user.postEmailOptOut}
          control={<Checkbox />}
          label="User opted out"
          labelPlacement="end"
        />
      </FormControl>
      <FormControl margin="normal">
        <FormControlLabel
          id="emailBlocked"
          onChange={e => handleToggleChange(e, "emailBlocked")}
          value={user.emailBlocked}
          checked={user.emailBlocked}
          control={<Checkbox />}
          label="Bounce Blocked"
          labelPlacement="end"
        />
      </FormControl>
    </Paper>
  );
}

export default UserView;
