import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";

import { CC_LOCAL_STORAGE_KEY, ccLoginValid } from "../../utils/cc";
import { useGoogleAuth } from "../../contexts/auth";

function Login() {
  const { isSignedIn, signIn } = useGoogleAuth();
  const history = useHistory();

  useEffect(() => {
    // get the access token from the URL
    if (window.location.hash) {
      const ccAccessToken = window.location.hash.substring(1).split("&")[0].split("access_token=")[1];
      localStorage.setItem(CC_LOCAL_STORAGE_KEY, JSON.stringify({
        expiresAt: Date.now() + 3600000, // expire in an hour for safety
        token: ccAccessToken,
      }));

      window.close();
    }
  }, []);

  useEffect(() => {
    if (isSignedIn && ccLoginValid()) {
      return history.push("/");
    }
  }, []);

  return (
      <Button variant="contained" color="primary" onClick={() => signIn()}>
        Sign in
      </Button>
  );
}

export default Login;
