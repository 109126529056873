import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";
import { GoogleAuthProvider } from "./contexts/auth";

function App() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
  }));

  const classes = useStyles();

  return (
    <Router>
      <GoogleAuthProvider>
        <div className={classes.root}>
          <CssBaseline />

          <AppBar />
          <Drawer />
        </div>
      </GoogleAuthProvider>
    </Router>
  );
}

export default App;
