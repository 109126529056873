import React, { useEffect } from "react";
import { Route, useHistory } from "react-router";

import { useGoogleAuth } from "../contexts/auth";
import { ccLoginValid } from "../utils/cc";

function ProtectedRoute(props) {
  const { isSignedIn } = useGoogleAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isSignedIn || !ccLoginValid()) {
      history.push(`/login${window.location.hash}`);
    }
  }, []);

  return <Route {...props} />;
}

export default ProtectedRoute;
