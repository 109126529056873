import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { FormLabel, Snackbar, Grid, Button, Box, CircularProgress, Paper, Typography, Divider, TextField, FormControl, Select, InputLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { DEFAULT_SNACKBAR_TIME, DEFAULT_SNACKBAR, ID_TO_USER_STATUS, DEFAULT_NEW_USER, getError } from "../../utils/constants";
import { getCCToken } from "../../utils/cc";

function CreateUserView() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto"
    },
    acctLink: {
      color: theme.palette.primary.main
    }
  }));

  const classes = useStyles();
  const history = useHistory();

  const { acctID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [user, setUser] = useState(DEFAULT_NEW_USER);
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();

    setUser({...user, acctID: parseInt(acctID) });
  }, [acctID]);

  useEffect(() => {
    return () => {
      setAccount({});
      setUser(DEFAULT_NEW_USER);
      setSnackbar(DEFAULT_SNACKBAR);
      setError(undefined);
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/accounts/${acctID}/info`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const accountJson = await res.json();
      setAccount(accountJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${googleUser.tokenId}`,
          "CC-Authorization": `${getCCToken()}`,
        },
        body: JSON.stringify(user),
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const userIDJson = await res.json();
      const userID = userIDJson.userID;
      history.push(`/accounts/${acctID}/users/${userID}`);
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: getError(e)});
    }
  }

  const handleFieldChange = (event) => {
    event.preventDefault();
    const property = event.target.id;
    setUser({...user, [property]: event.target.value});
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
        <Alert severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Title>
            New User
          </Title>

          <Typography variant="caption" display="block" gutterBottom>
            <Link className={classes.acctLink} to={`/accounts/${account.acctID}`}>Account Name: {account.acctName} | Account ID: {account.acctID}</Link>
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Create
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <FormControl margin="normal">
        <FormLabel component="legend">Basic Info</FormLabel>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="firstName" label="First Name" onChange={handleFieldChange} value={user.firstName} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="lastName" label="Last Name" onChange={handleFieldChange} value={user.lastName} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="user-status-selector">User Status</InputLabel>
        <Select
          labelId="user-status-selector"
          id="userStatusID"
          value={user.userStatusID}
          onChange={handleFieldChange}
          native={true}
          variant="outlined"
        >
          {Object.keys(ID_TO_USER_STATUS).map(i => <option key={i} value={i}>{ID_TO_USER_STATUS[i]}</option>)}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField disabled variant="outlined" id="acctID" label="Account ID" onChange={handleFieldChange} value={user.acctID} />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField variant="outlined" id="email" label="Email" onChange={handleFieldChange} value={user.email} />
        <Typography variant="caption" display="block">
          Must be unique across all accounts
        </Typography>
      </FormControl>
    </Paper>
  );
}

export default CreateUserView;
