import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

import { makeStyles } from "@material-ui/core/styles";
import { TableHead, CircularProgress, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useGoogleAuth } from "../../contexts/auth";
import Title from "../../components/Title";
import { getError } from "../../utils/constants";

function TrialsList() {
  const { googleUser } = useGoogleAuth();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "scroll"
    },
    formControl: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 150,
    },
    trialRow: {
      textDecoration: "none"
    }
  }));

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [trials, setTrials] = useState([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();

    return () => {
      setError(undefined);
    }
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/trials`, {
        headers: {
          "Authorization": `Bearer ${googleUser.tokenId}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const trialsJson = await res.json();
      setTrials(trialsJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Title>Pending Trial Requests</Title>

      <Typography variant="overline" display="block" color="textSecondary">
        Showing {trials.length.toLocaleString()} pending trial requests
      </Typography>

      <TableContainer component={Paper}>
        <Table classNames={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trials.map((t, i) => (
              <TableRow hover={true} component={Link} className={classes.trialRow} to={`/trials/${t.trialID}`} key={i}>
                <TableCell>
                  {t.company}
                </TableCell>
                <TableCell>
                  {t.firstName}
                </TableCell>
                <TableCell>
                  {t.lastName}
                </TableCell>
                <TableCell>
                  {dayjs(t.tsAdded).format("MM-DD-YYYY h:m A")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TrialsList;
